import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const AccordionWrapper = styled.div`
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e3e3e3;

  cursor: pointer;
  line-height: 0;
  h4.sans {
    line-height: 1em;
    margin-top: 1.5rem;
    margin-bottom: 1.75rem;

    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      margin-bottom: 1.125rem;
      font-size: 1rem;
      line-height: 1.0625rem;
    }
  }
`;
export const AccordionContent = styled.div`
  height: auto;
  overflow: hidden;
  display: inline-block;
  transition: all ease-out 0.5s;
  max-height: 0;
  &.active {
    max-height: 1000px;
  }
`;
export const AccordionNavigation = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transform: scaleY(1);
  transition: all 1s ease-out;
  .arrowDown {
    width: 20px;
    height: 12px;
    &.active {
      transform: scaleY(-1);
    }
  }
`;
