import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const NavBreadcrumb = styled.div`
  margin-top: 5.6875rem;
  margin-bottom: 2.3125rem;
  color: ${themeGet('colors.spinDark')};
  .arrow {
    margin-right: 0.6875rem;
    .arrowLeft {
      transform: scaleX(-1);
      height: 11px;
      position: relative;
      top: -4px;
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-bottom: 1.6875rem;
  }
`;
export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: row;
  .accordionWrapper {
    &:first-child {
      margin-top: 30px;
      border-top: 1px solid #e3e3e3;
    }
  }
  @media only screen and (max-width: 1368px) {
    .carouselWrapper {
      min-width: 50vw;
      .carouselFeatureImage {
        width: 50vw;
        height: 50vw;
        margin: 0 auto;
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    flex-direction: column;
  }
  h2 {
    &.onlyMobile {
      margin-top: 0;
      margin-bottom: 1.625rem;
    }
    &.onlyDesktop {
      margin-bottom: 1.1875rem;
    }
  }
`;
export const ProductInformation = styled.div`
  margin-left: 5.625rem;
  min-width: 300px;
  h2 {
    margin-top: 0;
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
  @media only screen and (max-width: 1368px) {
    margin-left: 2.5rem;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-left: 0;
    h2 {
      font-size: 1.875rem;
      line-height: 2.1875rem;
    }
  }
`;
export const ProductDescription = styled.div`
  font-size: 1rem;
  line-height: 1.625rem;

  .readMore {
    color: ${themeGet('colors.spinDark')};
    text-decoration: underline;
  }
  .whereTobuy {
    padding: 30px 0 50px;
  }
  .ps-widget {
    background-color: ${themeGet('colors.spinDark')} !important;
    font-family: ${themeGet('fonts.sans')} !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    border-radius: 30px !important;
    border: solid 1px ${themeGet('colors.whiteSnow')} !important;
  }
  .ps-disabled {
    display: none !important;
  }
  .ps-widget:hover {
    background-color: ${themeGet('colors.white')} !important;
    color: ${themeGet('colors.spinDark')} !important;
    border: solid 1px ${themeGet('colors.spinDark')} !important;
  }
  .rating-summary-wrapper {
    padding: 20px 0 20px 5px;
  }
  .reviews-details-wrapper {
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
`;

export const AccordionList = styled.div`
  margin-bottom: 5rem;
  li {
    font-size: 1rem;
    line-height: 1.625rem;
    margin-bottom: 2.25rem;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-bottom: 3.5625rem;
    &,
    li {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }
    h4 {
      font-size: 1rem;
      line-height: 1.625rem;
    }
  }
`;

// Use Aspect Ratio Box
// to size video to 16:9
export const ProductVideo = styled.div`
  .aspectRatioBox {
    display: inline-block;
    width: 100%;
    height: 0;
    padding-top: 56%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    .reactPlayer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-bottom: 3.8125rem;
    h4 {
      margin-bottom: 2.625rem;
    }
  }
`;
