import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import arrowDown from '../../images/arrowDown.svg';

import {
  AccordionWrapper,
  AccordionContent,
  AccordionNavigation,
} from './styles.js';

const ProductAccordion = ({ title, children, expanded }) => {
  const [active, setStateActive] = useState(false || expanded);
  const toggleState = () => {
    setStateActive(!active);
  };
  return (
    <AccordionWrapper className="accordionWrapper">
      <AccordionNavigation
        onClick={() => {
          toggleState();
        }}
      >
        <h4 className="sans accordionTitle">{title}</h4>
        <img
          alt=""
          role="presentation"
          className={`arrowDown ${active ? 'active' : ''}`}
          src={arrowDown}
        />
      </AccordionNavigation>
      <AccordionContent className={active ? 'active' : ''}>
        {children}
      </AccordionContent>
    </AccordionWrapper>
  );
};
export default ProductAccordion;
